import HeaderFour from "./Headers/Header-Four";

const HeaderBasic = () => {
  return (
    <>
      <header className="rbt-header rbt-header-4">
        <div className="rbt-sticky-placeholder"></div>
        <HeaderFour
          sticky="bg-color-white header-sticky"
          gapSpaceBetween="header-space-betwween"
          container="container-fluid"
          navigationEnd="rbt-navigation-start"
          btnClass="rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none"
          btnText="Enroll Now"
        />
      </header>
    </>
  );
};

export default HeaderBasic;
