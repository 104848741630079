import { useRouter } from "next/router";
import Link from "next/link";
import { useState } from "react";
import MenuData from "../../data/MegaMenu";

const Nav = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const router = useRouter();

  const isActive = (href) => router.pathname === href;

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
  };

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="has-dropdown">
          <Link
            className={`${activeMenuItem === "dashboard" ? "open" : ""}`}
            href="#"
            onClick={() => toggleMenuItem("dashboard")}
          >
            Nuestra Institución
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "dashboard" ? "active d-block" : ""
            }`}
          >
            {MenuData &&
              MenuData.ourInstitutionData.menuItems?.map((value, innerIndex) => (
                <li key={innerIndex}>
                  <Link href={value.link}>{value.title}</Link>
                </li>
              ))
            }
          </ul>
        </li>
        <li className="has-dropdown">
          <Link
            className={`${activeMenuItem === "dashboard" ? "open" : ""}`}
            href="#"
            onClick={() => toggleMenuItem("dashboard")}
          >
            Herramientas
            <i className="feather-chevron-down"></i>
          </Link>
          <ul
            className={`submenu ${
              activeMenuItem === "dashboard" ? "active d-block" : ""
            }`}
          >
            {MenuData &&
              MenuData.toolsData.menuItems?.map((value, innerIndex) => (
                <li key={innerIndex}>
                  <Link href={value.link} target={value.target}>{value.title}</Link>
                </li>
              ))
            }
          </ul>
        </li>
        <li >
          <Link
            className={`${activeMenuItem === "dashboard" ? "open" : ""}`}
            href="/comming-soon"
          >
            Galería
          </Link>
        </li>
        <li className="">
          <Link
            className={`${activeMenuItem === "dashboard" ? "open" : ""}`}
            href="/calendar"
          >
            Calendario
          </Link>
        </li>
        <li className="">
          <Link
            className={`${activeMenuItem === "dashboard" ? "open" : ""}`}
            href="/world-schooling"
          >
            World Schooling
          </Link>
        </li>
        <li className="">
          <Link
            className={`${activeMenuItem === "dashboard" ? "open" : ""}`}
            href="/contact"
          >
            Contáctanos
          </Link>
        </li>
        
      </ul>
    </nav>
  );
};
export default Nav;
